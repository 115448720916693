import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";

import { Button, Flex, Group, LoadingOverlay, Modal, SimpleGrid, Stepper, Textarea, Title, rem, TextInput, Select, Text, HoverCard, Radio } from "@mantine/core";
import { ReactNode, useState } from "react";
import { useForm } from "@mantine/form";
import {
    IconCircleCheck,
    IconTypography,
    IconVolume,
    IconFile,
    IconInfoCircle
} from "@tabler/icons-react";

import { useApiErrorHandler } from "../../hooks.ts";
import Api from "../../api.ts";
import IconRadio from "../../components/IconRadio.tsx";
import MediaUpload from "../../components/MediaUpload.tsx";
import { useNavigate } from "react-router-dom";


interface SummaryTextForm {
    name: string,
    text: string
}

interface SummaryFileForm {
    name: string,
    file: File | null,
    lang: string
}

const languages = [
    { value: 'es-ES', label: 'Spanish' },
    { value: 'pt-PT', label: 'Portuguese' }
]


function SummaryCreateModal({opened, setOpened}: {
    opened: boolean,
    setOpened: (open: boolean) => void
}) {
    const { t } = useTranslation()
    const { getAccessTokenSilently } = useAuth0()
    const handleError = useApiErrorHandler()
    const navigate = useNavigate()
    const [selectedEmails, setSelectedEmails] = useState<string>("all-emails")
    const [sourceType, setSourceType] = useState<string>("TEXT")
    const [active, setActive] = useState(0)
    const [createLoading, setCreateLoading] = useState(false)
    const [highestStepVisited, setHighestStepVisited] = useState(active)

    async function getAccessToken() {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: import.meta.env.VITE_AUTH0_AUDIENCE,
            }
       })

       return accessToken
    }

    const summaryTextForm = useForm<SummaryTextForm>({
        initialValues: {
            name: "",
            text: ""
        },
        validate: {
            name: value => value.length < 1
        }
    })
    const summaryFileForm = useForm<SummaryFileForm>({
        initialValues: {
            name: "",
            file: null,
            lang: "es-ES"
        },
        validate: {
            name: value => value.length < 1
        }
    })

    function onChangeType(changedType: string) {
        setSourceType(changedType)
    }

    function goToNextStep(nextStep: number) {
        setActive((current) => (current < 3 ? current + 1 : current))
        setHighestStepVisited((hSC) => Math.max(hSC, nextStep))
    }

    function goToPrevStep() {
        setActive((current) => (current > 0 ? current - 1 : current))
    }

    const shouldAllowSelectStep = (step: number) => highestStepVisited >= step && active !== step


    function handleClose() {
        setOpened(false)
        setActive(0)
        summaryTextForm.reset()
        summaryFileForm.reset()
    }

    function onDropAudio(file: File) {
        summaryFileForm.setFieldValue("file", file)
    }

    function onDropFile(file: File) {
        summaryFileForm.setFieldValue("file", file)
    }

    async function onSubmitCreateSummaryText(values: SummaryTextForm) {
        setCreateLoading(true)
        Api.createSummaryText(await getAccessToken(), values.name, values.text)
            .then(() => {
                setCreateLoading(false)
                summaryTextForm.reset()
                handleClose()
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateLoading(false)
            })
    }

    async function onSubmitCreateSummaryAudio(values: any) {
        setCreateLoading(true)
        Api.createSummaryAudio(await getAccessToken(), values.name, values.file, values.lang)
            .then((data) => {
                setCreateLoading(false)
                summaryFileForm.reset()
                navigate('/summaries/' + data.id)
                handleClose()
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateLoading(false)
            })
    }

    async function onSubmitCreateSummaryFile(values: any) {
        setCreateLoading(true)
        Api.createSummaryFile(await getAccessToken(), values.name, values.file, selectedEmails)
            .then(() => {
                setCreateLoading(false)
                summaryFileForm.reset()
                handleClose()
            }).catch((err) => {
                console.error(err);
                handleError(err)
                setCreateLoading(false)
            })
    }

    let typeForm: ReactNode
    if (sourceType === "TEXT") {
        typeForm = (
                <form onSubmit={summaryTextForm.onSubmit(onSubmitCreateSummaryText)}>
                <TextInput
                    withAsterisk
                    label={t("Name")}
                    required
                    placeholder={t("Super duper name")}
                    {...summaryTextForm.getInputProps("name")}
                    p={'xl'}
                />

                <Textarea
                    label={t("Input text")}
                    withAsterisk
                    required
                    {...summaryTextForm.getInputProps("text")}
                />
                
                <Group justify="center" mt={48}>
                    <Button type="submit" variant="default" onClick={goToPrevStep}>
                        Back
                    </Button>
                    <Button type="submit" autoFocus>Create</Button>
                </Group>
            </form>
        )
    } else if (sourceType === "AUDIO") {
        typeForm = (
            <form onSubmit={summaryFileForm.onSubmit(onSubmitCreateSummaryAudio)}>
                <TextInput
                    withAsterisk
                    label={t("Name")}
                    required
                    placeholder={t("Super duper name")}
                    {...summaryFileForm.getInputProps("name")}
                    mb={'md'}
                /> 
                <Select
                    label="Language"
                    placeholder="Choose a language"
                    data={languages}
                    mb={'md'}
                    {...summaryFileForm.getInputProps("lang")}
                />
                <MediaUpload
                    loading={false}
                    onDrop={onDropAudio}
                    onDelete={() => {summaryFileForm.setFieldValue("file", null)}}
                    acceptImages={false}
                    acceptVideos={false}
                    acceptAudios={true}
                    acceptFiles={false}
                    audio={summaryFileForm.values.file}
                />

                <Group justify="center" mt={48}>
                    <Button type="submit" variant="default" onClick={goToPrevStep}>
                        Back
                    </Button>
                    <Button type="submit" disabled={summaryFileForm.values.file === null} autoFocus>Create</Button>
                </Group>
            </form>
        )
    } else if (sourceType === "FILE") {
        typeForm = (
            <form onSubmit={summaryFileForm.onSubmit(onSubmitCreateSummaryFile)}>
                <TextInput
                    withAsterisk
                    label={t("Name")}
                    required
                    placeholder={t("Super duper name")}
                    {...summaryFileForm.getInputProps("name")}
                    mb={'md'}
                />
                <Radio.Group
                    label={
                        <Flex align="center" justify={'center'}>
                            <Text size="sm" mr={10}>
                                {t("Select which emails to summarize")}
                            </Text>
                            <HoverCard width={320} shadow="md" withArrow openDelay={100} closeDelay={100}>
                                <HoverCard.Target><IconInfoCircle color="orange"/></HoverCard.Target>
                                <HoverCard.Dropdown>
                                    <Text size="sm">
                                        If the provided .eml file represents a thread of emails, 
                                        you have the option to summarize either the first email, the last email, 
                                        or all emails within the thread.
                                    </Text>
                                </HoverCard.Dropdown>
                            </HoverCard>
                        </Flex>
                    }
                    value={selectedEmails}
                    onChange={(value) => setSelectedEmails(value)}>   
                        <Radio value="all-emails" variant="outline" mt={10} mb={10} label="All emails" />
                        <Radio value="last-email" variant="outline" mb={10} label="Last email only" />
                        <Radio value="first-email" variant="outline" mb={10} label="First email only" />
            </Radio.Group>
                <MediaUpload
                    loading={false}
                    onDrop={onDropFile}
                    onDelete={() => {summaryFileForm.setFieldValue("file", null)}}
                    acceptImages={false}
                    acceptVideos={false}
                    acceptAudios={false}
                    acceptFiles={true}
                    file={summaryFileForm.values.file}
                />
               
                <Group justify="center" mt={48}>
                    <Button type="submit" variant="default" onClick={goToPrevStep}>
                        Back
                    </Button>
                    <Button type="submit" disabled={summaryFileForm.values.file === null} autoFocus>Create</Button>
                </Group>
            </form>
        )
    }else {
        throw new Error(`Type not implemented: ${sourceType}`)
    }

    const humanReadableType = {
        "TEXT": t("Text"),
        "AUDIO": t("Audio"),
        "FILE": t("File")
    }
    

    
    return (
        <Modal opened={opened} onClose={() => handleClose()} size="65%" >
            <Flex
                direction="column"
                mt={rem(30)}
                mb={rem(50)}
                pr={40}
                pl={40}
                pb={10}
            >
                <Title>Create summary</Title>
            </Flex>
            <LoadingOverlay visible={createLoading}/>

            <Stepper
                active={active}
                pr={40}
                pl={40}
                pb={10}
                onStepClick={setActive}
                completedIcon={<IconCircleCheck style={{width: rem(18), height: rem(18)}}/>}
            >
                <Stepper.Step
                    label="Type"
                    description={(sourceType == null || active === 0) ? "Choose the source type" : humanReadableType[sourceType]}
                    allowStepSelect={shouldAllowSelectStep(0)}
                >
                    <SimpleGrid cols={3} mt={64} spacing="xl">
                        <IconRadio
                            enabled={true}
                            checked={sourceType === "TEXT"}
                            defaultChecked={true}
                            onChange={() => onChangeType("TEXT")}
                            title={humanReadableType["TEXT"]}
                            description="Create a summary from text"
                            icon={<IconTypography/>}
                        />
                        <IconRadio
                            enabled={true}
                            checked={sourceType == "AUDIO"}
                            defaultChecked={false}
                            onChange={() => onChangeType("AUDIO")}
                            title={humanReadableType["AUDIO"]}
                            description="Create a summary from audio"
                            icon={<IconVolume/>}
                        />
                        <IconRadio
                            enabled={true}
                            checked={sourceType == "FILE"}
                            defaultChecked={false}
                            onChange={() => onChangeType("FILE")}
                            title={humanReadableType["FILE"]}
                            description="Create a summary from .eml file"
                            icon={<IconFile/>}
                        />
                    </SimpleGrid>
                    <Group justify="center" mt={48}>
                        <Button onClick={() => goToNextStep(active + 1)}>Next</Button>
                    </Group>
                </Stepper.Step>
                <Stepper.Step
                    label="Source"
                    description="Upload the source"
                    allowStepSelect={shouldAllowSelectStep(1)}
                >
                    {typeForm}
                </Stepper.Step>
            </Stepper>
        </Modal>
    )
}

export default SummaryCreateModal